/*** 

====================================================================
			Fonts
====================================================================

***/

@import url('https://fonts.googleapis.com/css?family=Lato:400,700,900|Roboto:400,500,700,900&display=swap');

/* 

font-family: 'Roboto', sans-serif;
font-family: 'Lato', sans-serif;

*/

@import url('fontawesome-all.css');
@import url('animate.css');
@import url('hover.css');

/*** 

====================================================================
	Reset
====================================================================

 ***/

* {
  margin: 0px;
  padding: 0px;
  border: none;
  outline: none;
}
:root{
  --orange-dark: #fa6634;
  --orange-light-light: #e76311;
  --orange: #fc6238;
  --orange-profile-mypage: #ff9046;
  --orange-profile-mypage-two: #f17928;
  --orange-two: #f67921;
  --orange-light: #f57d1d;
  --orange-light-wallet: #ed6b1d;
  --orange-light-trading: #d9641b;
  --orange-light-trading-two: #ef6c1a;
  --red-color: #f44336;
  --trading-white: #e9e9eb;
  --light-dark-grey: #414152;
  --dark-background-blue: #21212f;
  --white: #fff;
  --off-triplewhite-two: #ffffff;
  --navy-dark-blue: #2154cf;
  --dark-blue-two: #1b1b27;
  --dark-blue-widthraw: #21212f;
  --dark-black-trading: #0a0a12;
  --dark-blue-widthraw-two: #282834;
  --blue-dark-wallet: #2b2b3b;
  --dark-light-black: #2c2c2c;
  --dark-black-two: #272727;
  --light-white-ftwo: #f2f2f2;
  --light-white-fnine: #f9f9f9;
  --blueish-blue: #23334c;
  --black-dark-oneb: #1b1b27;
  --purple-light: #413598;
  --purple-light-mypage: #392eae;
  
}
/*** 

====================================================================
	Global Settings
====================================================================

***/

body {
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  color: var(--white);
  line-height: 28px;
  font-weight: 400;
  background: var(--dark-background-blue);
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
}

body.black {
  background: #000;
}

a {
  text-decoration: none;
  cursor: pointer;
  color: var(--navy-dark-blue);
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

button {
  cursor: pointer;
  text-decoration: none;
  outline: none !important;
}


a:hover,
a:focus,
a:visited {
  text-decoration: none !important;
  outline: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  position: relative;
  font-weight: normal;
  margin: 0px;
  background: none;
  line-height: 1.2em;
  font-family: 'Lato', sans-serif;
}

input,
button,
select,
textarea {
  font-family: 'Lato', sans-serif;
}

textarea {
  overflow: hidden;
  resize: none;
}

figure {
  margin: 0;
  padding: 0;
}

p,
.text {
  position: relative;
  font-size: 14px;
  line-height: 28px;
  font-weight: 400;
  color: #777777;
  margin: 0;
  font-family: 'Roboto', sans-serif;
}

::-webkit-input-placeholder {
  color: inherit;
}

::-moz-input-placeholder {
  color: inherit;
}

::-ms-input-placeholder {
  color: inherit;
}

.dark {
  background: var(--dark-background-blue);
  color: var(--white);
  min-height: 100vh;
}

.dark .table {
  color: var(--white);
}

.page-wrapper-inner {
  position: relative;
  margin: 0 auto;
  width: 100%;
  min-height: 100vh;
  color: var(--white);
  min-width: 300px;
  overflow: hidden;
}

ul,
li {
  list-style: none;
  padding: 0px;
  margin: 0px;
}

img {
  display: inline-block;
  max-width: 100%;
}

.theme-btn {
  display: inline-block;
  text-align: center;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -ms-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.centered {
  text-align: center !important;
}

/*Btn Style One*/

.btn-style-one {
  position: relative;
  font-size: 13px;
  line-height: 30px;
  color: var(--white);
  padding: 10px 44px;
  text-transform: capitalize;
  font-weight: 600;
  border-radius: 50px;
  background-image: linear-gradient(var(--orange-light), var(--orange));
  font-family: 'Roboto', sans-serif;
  display: inline-block;

}

.btn-style-one:hover {
  color: var(--orange-dark);
  -webkit-box-shadow: rgba(255, 255, 255, 1) 0 0px 0px 40px inset;
  -moz-box-shadow: rgba(255, 255, 255, 1) 0 0px 0px 40px inset;
  -ms-box-shadow: rgba(255, 255, 255, 1) 0 0px 0px 40px inset;
  -o-box-shadow: rgba(255, 255, 255, 1) 0 0px 0px 40px inset;
  box-shadow: rgba(255, 255, 255, 1) 0 0px 0px 40px inset;
}

.btn-style-one:before {
  position: absolute;
  top: -1px;
  right: -1px;
  left: -1px;
  bottom: -1px;
  content: "";
  border-radius: 50px;
  transition: all 300ms ease;
  opacity: 0;
}

.btn-style-one:hover:before {
  opacity: 1;
}

/*Btn Style Two*/

.btn-style-two {
  position: relative;
  font-size: 18px;
  line-height: 30px;
  color: var(--white);
  padding: 15px 44px;
  text-transform: capitalize;
  font-weight: 400;
  border-radius: 50px;
  background-color: var(--orange-dark);
  font-family: 'Lato', sans-serif;
  display: inline-block;

}

.btn-style-two:hover {
  color: var(--white);
  -webkit-box-shadow: rgba(10, 21, 169, 1) 0 0px 0px 40px inset;
  -moz-box-shadow: rgba(10, 21, 169, 1) 0 0px 0px 40px inset;
  -ms-box-shadow: rgba(10, 21, 169, 1) 0 0px 0px 40px inset;
  -o-box-shadow: rgba(10, 21, 169, 1) 0 0px 0px 40px inset;
  box-shadow: rgba(10, 21, 169, 1) 0 0px 0px 40px inset;
}

.btn-style-two:before {
  position: absolute;
  top: -1px;
  right: -1px;
  left: -1px;
  bottom: -1px;
  content: "";
  border-radius: 50px;
  transition: all 300ms ease;
  opacity: 0;
}

.btn-style-two:hover:before {
  opacity: 1;
}


/*Btn Style Two*/

.btn-style-three {
  position: relative;
  font-size: 16px;
  line-height: 30px;
  color: var(--white);
  padding: 9px 54px;
  text-transform: capitalize;
  font-weight: 400;
  border-radius: 50px;
  background-color: var(--orange-light-light);
  font-family: 'Lato', sans-serif;
  display: inline-block;

}

.btn-style-three:hover {
  color: var(--orange-light-light);
  -webkit-box-shadow: rgba(255, 255, 255, 1) 0 0px 0px 40px inset;
  -moz-box-shadow: rgba(255, 255, 255, 1) 0 0px 0px 40px inset;
  -ms-box-shadow: rgba(255, 255, 255, 1) 0 0px 0px 40px inset;
  -o-box-shadow: rgba(255, 255, 255, 1) 0 0px 0px 40px inset;
  box-shadow: rgba(255, 255, 255, 1) 0 0px 0px 40px inset;
}

.btn-style-three:before {
  position: absolute;
  top: -1px;
  right: -1px;
  left: -1px;
  bottom: -1px;
  content: "";
  border-radius: 50px;
  transition: all 300ms ease;
  opacity: 0;
}

.btn-style-three:hover:before {
  opacity: 1;
}

.theme_color {
  color: var(--orange-dark);
}

.pull-right {
  float: right;
}

.pull-left {
  float: left;
}

.preloader {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 999999;
  background-color: var(--white);
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url(../../static/images/icons/preloader.svg);
}

img {
  display: inline-block;
  max-width: 100%;
  height: auto;
}

.outer-container {
  position: relative;
  padding: 0px 15px;
  max-width: 1540px;
  margin: 0 auto;
}




/*** 

====================================================================
	Group Table Area
====================================================================

 ***/
.group-table-area {
  position: relative;
  padding: 60px 0;
}

.group-table-area .dash-top-title {
  padding: 15px 40px 20px;
}

.group-table-area .dash-top-title h2 {
  display: inline-block;
}

.group-table-area .dash-top-title a {
  float: right;
  border: 1px solid var(--white);
  padding: 1px 35px;
  border-radius: 45px;
  color: var(--white);
}

.group-table-area .group-main-area {
  background-color: var(--dark-blue-two);
  padding: 55px 40px;
}

.group-table-area .group-main-area .table {
  border: 1px solid var(--white);
}

.group-table-area .group-main-area .table td {
  vertical-align: middle;
}

.group-table-area .group-main-area .table thead th {
  border: 0;
  background-color: var(--orange-light-light);
}

.group-table-area .group-main-area .table .icn-box {
  position: relative;
  display: inline-block;
  margin-left: 5px;
}

.group-table-area .group-main-area .table .icn-box i {
  display: block;
  position: absolute;
  top: -8px;
}

.group-table-area .group-main-area .table .icn-box i:first-child {
  line-height: 0px;
  position: absolute;
  top: -9px;
}

.group-table-area .group-main-area .table td .group-icon {
  margin-right: 11px;
}

.group-table-area .group-main-area .table .active-status {
  width: 10px;
  height: 10px;
  background-color: #58a25e;
  position: relative;
  display: inline-block;
  margin-right: 5px;
  border-radius: 50%;
}


/*** 

====================================================================
	group page3 Section
====================================================================

 ***/

.group-page3-section {
  position: relative;
  padding: 60px 0;
}


.group-page3-section .detail-area {
  position: relative;
  background: var(--dark-blue-two);
}

.group-page3-section .detail-area .boxes {
  position: relative;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  padding: 12px 15px;
}

.group-page3-section .detail-area .boxes:last-child {
  border-bottom: none;
}

.group-page3-section .detail-area .boxes h4 {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 10px;
}

.group-page3-section .detail-area .boxes h4 span {
  font-size: 14px;
}

.group-page3-section .detail-area .boxes p {
  color: var(--white);
}

.group-page3-section .detail-area .boxes h5 {
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  display: inline-block;
}

.group-page3-section .detail-area .boxes a {
  float: right;
  color: var(--white);
  padding: 1px 29px;
  border-radius: 25px;
  margin-top: -19px;
  margin-right: 30px;
  text-transform: uppercase;
}

.group-page3-section .detail-area .boxes .white-btn {
  border: 1px solid var(--white);
}

.group-page3-section .detail-area .boxes.status h5 {
  font-size: 12px;
}

.group-page3-section .detail-area .boxes.status h5 span {
  width: 10px;
  height: 10px;
  background: red;
  display: inline-block;
  border-radius: 50%;
  margin: 0 4px 0px 9px;
}

.group-page3-section .detail-area .boxes.status h5 i {
  font-style: normal;
}

.group-page3-section .detail-area .boxes .orenge-btn {
  background: var(--orange-light-light);
}

.group-page3-section .search-form {
  float: right;
  margin-left: 33px;
  position: relative;
}

.group-page3-section .search-form .froum-group {
  width: 100%;
  position: relative;
}

.group-page3-section .search-form .froum-group .control-form {
  width: 210px;
  background: none;
  color: var(--white);
  font-size: 14px;
  border: 1px solid var(--white);
  background: none;
  border-radius: 40px;
  padding: 3px 25px;
  position: relative;
}

.group-page3-section .search-form .froum-group button {
  position: absolute;
  background: no-repeat;
  color: var(--white);
  right: 17px;
  top: 4px;
}

.group-page3-section .management-table .dash-top-title h2 {
  display: inline-block;
}

.group-page3-section .management-table {
  position: relative;
  background: var(--dark-blue-two);
}

.group-page3-section .management-table .tables {
  padding: 50px 25px;
  min-height: 544px;
}

.group-page3-section .management-table .table thead th {
  border-bottom: 0;
  border-top: 0;
}

.group-page3-section .management-table .table .icn-box {
  position: relative;
  display: inline-block;
  margin-left: 5px;
}

.group-page3-section .management-table .table .icn-box i {
  display: block;
  position: absolute;
  top: -8px;
  color: var(--white);
}

.group-page3-section .management-table .table .icn-box i:first-child {
  line-height: 0px;
  position: absolute;
  top: -9px;
}

/*** 

====================================================================
	devinded table
====================================================================

 ***/

.devinded-table {
  margin-top: 30px;
  position: relative;
  background: var(--dark-blue-two);
}

.devinded-table .tables {
  padding: 25px;
}

.devinded-table .table {
  text-align: center;
}

.devinded-table .table thead th {
  border-top: 0;
  border-bottom: 0;
}

.devinded-table .table .icn-box {
  position: relative;
  display: inline-block;
  margin-left: 5px;
}

.devinded-table .table .icn-box i {
  display: block;
  position: absolute;
  top: -8px;
  color: var(--white);
}

.devinded-table .table .icn-box i:first-child {
  line-height: 0px;
  position: absolute;
  top: -9px;
}

.devinded-table .table td {
  border-top: 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

/*** 

====================================================================
	Sms Anthtication
====================================================================

 ***/
/* .sms-anthtication {
  position: relative;
  padding: 60px 0;
}
.sms-anthtication .iti__country-list {
  background-color: var(--dark-blue-two);
}
.sms-anthtication .sms-anthtication-form {
  background: var(--dark-blue-two);
}
.sms-anthtication .sms-anthtication-form .form-area {
  padding: 50px;
}
.sms-anthtication .sms-anthtication-form .form-area .form-item {
  width: 100%;
  margin-bottom: 25px;
}
.sms-anthtication .sms-anthtication-form .form-area .iti.iti--allow-dropdown {
  width: 100%;
}
.sms-anthtication .bottom-btn {
  background: #30303b;
  padding: 40px;
}
.sms-anthtication .bottom-btn button {
  padding: 10px 75px;
}
.sms-anthtication .notice-bottom {
  position: relative;
  padding-top: 17px;
  text-align: center;
}
.sms-anthtication .notice-bottom p {
  font-size: 16px;
  color: var(--var(--white));
}
.sms-anthtication .notice-bottom p span {
  margin-right: 10px;
} */

/*** 

====================================================================
	Google Auth Step
====================================================================

 ***/





/*Light Version*/
/* 
.light {
  background: var(--var(--white));
  color: #23334c;
} */



















/* .light .history-sec ul.orderlist li a,
.light .date-sec ul li a,
.light .orders-sec .group-price-bock ul.grap-btn li,
.light .inner-form-block ul.limint-btn li a,
.light .withdraw-manag-sec .withdraw-form .form-area .froum-group .control-form,
.light .table-area .search-table .table td .icon,
.light .table-area .search-table .table .custom-checkbox .custom-control-input:checked~.custom-control-label::before,
.light .table-area .search-table .table .custom-control-input:checked~.custom-control-label::before,
.light .top-search-area form .froum-group .control-form,
.light .sms-anthtication .sms-anthtication-form .form-area input,
.ligth .withdraw-balance .group-form .amount-fees,
.light .withdraw-balance .control-form {
  border-color: #23334c;
  
} */












.light .check {
  border: 5px solid #0e2745;
}

.light .check::before {
  background-color: #0e2745;
}

















